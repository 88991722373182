<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-08 09:38:53
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-06 18:08:35
-->
<template>
  <div class="qj-login">
    <el-container>
      <!-- <el-header>
        <div class="header-body">
          <img
            class="headers-icon"
            src="../../assets/img/common/img-common-icon.png"
          />
          <div class="header-home" @click="$router.push('/home')">
            返回首页
          </div>
        </div>
      </el-header> -->
      <el-main>
        <div class="login-body">
          <div class="left">
            <img src="../../assets/img/login/img-login-bg.png" />
          </div>
          <div class="right">
            <div class="title">
              登录
            </div>
            <div class="title-two">
              注册成为能匠城市合伙人后，您将可以接单和商品购买!
            </div>
            <el-form
              ref="loginForm"
              :model="loginForm"
              :rules="rules"
              size="mini"
            >
              <el-form-item prop="phone">
                <el-input
                  v-model="loginForm.phone"
                  placeholder="请输入手机号"
                  prefix-icon="iconfont icon-shouji"
                ></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  v-model="loginForm.password"
                  show-password
                  placeholder="请输入密码"
                  prefix-icon="iconfont icon-mima"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="desc">
              温馨提示：切勿将账号和密码交由他人使用，以免造成您的损失，如果有所损失我们将不予承担责任。
            </div>
            <!-- <div class="forgot-password">
              忘记密码？
            </div> -->
            <div class="operation">
              <el-button type="primary" @click="submitForm">立即登录</el-button>
              <div class="operation-right">
                <div class="tip" @click="editPass">忘记密码？</div>
                <!-- <el-button @click="$router.push('/register')">立即注册</el-button> -->
              </div>
            </div>
            <!-- <div class="desc">
              信息提交完成后我们将对您所提交的信息进行审核，审核后我们将对您发送审核信息，保持手机畅通。
            </div> -->
          </div>
        </div>
      </el-main>
    </el-container>
    <edit-pass ref="editPass"></edit-pass>
  </div>
</template>

<script>
import { session } from "@/utils/sessions";
import editPass from "./editPass";
export default {
  components: {
    editPass
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号"));
      } else {
        const reg = /^1[1-9][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    var checkPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        phone: "", //手机号
        password: "" // 密码
      },
      rules: {
        phone: [{ validator: checkPhone, trigger: "blur" }],
        password: [{ validator: checkPass, trigger: "blur" }]
      }
    };
  },
  mounted() {},
  methods: {
    submitForm() {
      let sefl = this;

      this.$refs.loginForm.validate(valid => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: "登录中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });

          let params = { ...this.loginForm };
          this.$api
            .login(params)
            .then(res => {
              this.$message({
                message: "恭喜您，登录成功！",
                type: "success"
              });

              session.setLoginToken(res.data.token);
              sefl.$api
                .getUserInfo({ id: res.data.id })
                .then(user => {
                  session.setUsers(user.data);
                  let path = session.getFullPath();
                  this.$store.dispatch("getUser", true);
                  if (path) {
                    session.removeFullPath();
                    this.$router.push(path);
                  } else {
                    this.$router.push("/home");
                  }
                })
                .catch(msg => {
                  this.$message.error(msg?.msg);
                })
                .finally(() => {
                  loading.close();
                });
            })
            .catch(msg => {
              this.$message.error(msg?.msg);
            })
            .finally(() => {
              loading.close();
            });
        } else {
          return false;
        }
      });
    },
    editPass() {
      this.$refs.editPass.init();
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>

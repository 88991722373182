<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-23 09:12:41
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-10 10:47:26
-->
<template>
  <div class="login-edit-pass">
    <modal :visible="visible" title="忘记密码" @close="handleClose">
      <div class="update-pass-body">
        <el-form
          label-position="right"
          label-width="85px"
          ref="ruleForm"
          :model="form"
          :rules="rules"
        >
          <el-form-item label="手机号:" prop="phone">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="验证码:" prop="code">
            <el-input
              class="yzm-input"
              v-model="form.code"
              placeholder="请输入手机号验证码"
            >
              <el-link
                type="primary"
                slot="append"
                :class="{ 'link-disabled': oldIsDisabled }"
                @click="oldSendSms"
              >
                {{ oldButtonText }}
              </el-link>
            </el-input>
          </el-form-item>
          <el-form-item label="新密码:" prop="password1">
            <el-input show-password v-model="form.password1"></el-input>
          </el-form-item>
          <el-form-item label="确认密码:" prop="password2">
            <el-input show-password v-model="form.password2"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取消</el-button>
          <el-button type="primary" @click="openTipModal">保存</el-button>
        </span>
      </div>
    </modal>
    <tip-modal :visible="visibleTip" @close="handleClose" @ok="updatePassword">
      <span>确定修改密码吗？</span>
    </tip-modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import TipModal from "@/components/TipModal";
export default {
  components: {
    Modal,
    TipModal
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        if (this.checkMobile(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    var checkPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    var checkVerifyPwd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password1) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      visibleTip: false,
      visible: false,
      form: {
        phone: "",
        code: "",
        password1: "",
        password2: ""
      },
      oldButtonText: "发送验证码",
      oldFlag: true,
      oldIsDisabled: false,
      rules: {
        phone: [{ validator: checkPhone, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        password1: [{ validator: checkPass, trigger: "blur" }],
        password2: [{ validator: checkVerifyPwd, trigger: "blur" }]
      }
    };
  },
  methods: {
    init() {
      this.form = {
        phone: "",
        code: "",
        password1: "",
        password2: ""
      };
      this.visible = true;
    },
    handleClose() {
      this.visible = false;
      this.visibleTip = false;
    },
    openTipModal() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.visibleTip = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 当前手机号
    oldSendSms() {
      if (!this.oldFlag) {
        return false;
      }
      
      let phone = this.form.phone;
      if (this.checkMobile(phone)) {
        this.sendSms(phone, () => {
          this.oldSendSmsText();
        });
      } else {
        this.$refs.ruleForm.validateField("phone");
      }
    },

    oldSendSmsText() {
      let self = this;
      let time = 60;
      self.oldButtonText = "已发送";
      self.oldIsDisabled = true;
      if (self.oldFlag) {
        self.oldFlag = false;
        let timer = setInterval(() => {
          time--;
          self.oldButtonText = time + " 秒";
          if (time === 0) {
            clearInterval(timer);
            self.oldButtonText = "重新获取";
            self.oldIsDisabled = false;
            this.oldFlag = true;
          }
        }, 1000);
      }
    },

    // 发送验证码
    sendSms(phone, callback) {
      const loading = this.$loading({
        lock: true,
        text: "获取验证码中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });

      let params = { phone: phone, status: 1 };
      this.$api
        .sendSms(params)
        .then(() => {
          callback();
        })
        .catch(msg => {
          this.$message.error(msg?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },
    // 验证手机号
    checkMobile(str) {
      let reg = /^1[3-9][0-9]\d{8}$/;
      if (reg.test(str)) {
        return true;
      } else {
        return false;
      }
    },

    updatePassword() {
      const loading = this.$loading({
        lock: true,
        text: "修改中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      let param = { ...this.form };
      this.$api
        .changePassword(param)
        .then(() => {
          this.$message({
            message: "恭喜您，修改密码成功！",
            type: "success"
          });
          this.visible = false;
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          this.visibleTip = false;
          loading.close();
        });
    }
  }
};
</script>

<style lang="less" scoped></style>
